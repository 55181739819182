import React from 'react'
// import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = ({ siteTitle }) => (
  <header style={{ display: 'flex' }}>
     <a href="/"
      className="link--kumya"
      style={{ display: `block`, textTransform: `uppercase`, padding: `.5em`, }}
      ><span data-letters={siteTitle}>{siteTitle}</span>
     </a>
  </header>
)

export default Header
